
  @import "~bulma/sass/utilities/functions";
@import "~bulma/sass/utilities/initial-variables";
@import "~bulma/sass/utilities/derived-variables";
@import "~bulma/sass/utilities/mixins";
  @import "@/scss/_bulmaOverridden";

  .bio-panel {
    height: 100%;
    padding-left: 2em;
    padding-right: 2em;
    padding-bottom: 2em;

    figure {
      max-width: 256px;
    }
  }


